<template>
  <section :aria-label="section.headline">
    <div
      :class="{ dark: section.dark_mode }"
      :style="styledBg"
      class="
        component
        position-relative
        c-coverage
        padding-y-24
        bp-768:padding-y-48
      "
    >
      <div class="row padding-x-12 position-relative z-index-3">
        <common-header
          :headline="section.headline"
          :sponsor="section.sponsor"
        />
      </div>
      <div
        v-if="template.name === 'coverage-1'"
        class="
          c-coverage--template-1
          row
          width-100
          position-relative
          z-index-3
          padding-x-12
        "
      >
        <div class="width-100 c-coverage__icons">
          <div class="c-coverage__icons--list display-flex flex-wrap-wrap">
            <template v-for="element in section.coverageElements">
              <template v-if="!element.is_custom">
                <div
                  class="
                    c-coverage__icons--item
                    flex-basis-100
                    bp-768:flex-basis-50
                    bp-1024:flex-basis-33
                    display-flex
                    flex-direction-column
                    align-items-center
                    justify-content-center
                    text-align-center
                  "
                  :key="element.id"
                  v-if="element.show && element.id == 'date'"
                >
                  {{ eleme }}
                  <div class="c-coverage__icons--item-icon">
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div
                    v-if="gameday && gameday.game && gameday.game.date"
                    class="c-coverage__primary"
                  >
                    {{
                      gameday.game.date
                        ? moment(gameday.game.date).format("MM.DD.YY")
                        : "N/A"
                    }}
                  </div>
                  <div class="c-coverage__secondary">Date</div>
                </div>
                <div
                  class="
                    c-coverage__icons--item
                    flex-basis-100
                    bp-768:flex-basis-50
                    bp-1024:flex-basis-33
                    display-flex
                    flex-direction-column
                    align-items-center
                    justify-content-center
                    text-align-center
                  "
                  :key="element.id"
                  v-if="element.show && element.id == 'time'"
                >
                  <div class="c-coverage__icons--item-icon">
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div
                    v-if="gameday && gameday.game && gameday.game.time"
                    class="c-coverage__primary"
                  >
                    {{ gameday.game.time }}
                  </div>
                  <div class="c-coverage__secondary">Time</div>
                </div>
                <div
                  class="
                    c-coverage__icons--item
                    flex-basis-100
                    bp-768:flex-basis-50
                    bp-1024:flex-basis-33
                    display-flex
                    flex-direction-column
                    align-items-center
                    justify-content-center
                    text-align-center
                  "
                  :key="element.id"
                  v-if="element.show && element.id == 'location'"
                >
                  <div class="c-coverage__icons--item-icon">
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div
                    v-if="gameday && gameday.game && gameday.game.location"
                    class="c-coverage__primary"
                  >
                    <div class="margin-b-6">{{ gameday.game.location }}</div>
                    <a
                      v-if="
                        gameday.facility.featured != null &&
                        gameday.facility.featured
                      "
                      :href="'/facilities/?id=' + gameday.facility.id"
                    >
                      {{ gameday.facility.title }}
                    </a>
                  </div>
                  <div class="c-coverage__secondary">Location</div>
                </div>
                <div
                  class="
                    c-coverage__icons--item
                    flex-basis-100
                    bp-768:flex-basis-50
                    bp-1024:flex-basis-33
                    display-flex
                    flex-direction-column
                    align-items-center
                    justify-content-center
                    text-align-center
                  "
                  :key="element.id"
                  v-if="
                    element.show &&
                    element.id == 'tv' &&
                    gameday &&
                    gameday.game &&
                    gameday.game.coverage.tv
                  "
                >
                  <div class="c-coverage__icons--item-icon">
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div
                    v-if="gameday && gameday.game && gameday.game.coverage.tv"
                    class="c-coverage__primary"
                    v-html="gameday.game.coverage.tv"
                  ></div>
                </div>
                <div
                  class="
                    c-coverage__icons--item
                    flex-basis-100
                    bp-768:flex-basis-50
                    bp-1024:flex-basis-33
                    display-flex
                    flex-direction-column
                    align-items-center
                    justify-content-center
                    text-align-center
                  "
                  :key="element.id"
                  v-if="
                    element.show &&
                    element.id == 'radio' &&
                    gameday &&
                    gameday.game &&
                    gameday.game.coverage.radio
                  "
                >
                  <div class="c-coverage__icons--item-icon">
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div
                    v-if="
                      gameday && gameday.game && gameday.game.coverage.radio
                    "
                    class="c-coverage__primary"
                    v-html="gameday.game.coverage.radio"
                  ></div>
                </div>
                <div
                  class="
                    c-coverage__icons--item
                    flex-basis-100
                    bp-768:flex-basis-50
                    bp-1024:flex-basis-33
                    display-flex
                    flex-direction-column
                    align-items-center
                    justify-content-center
                    text-align-center
                  "
                  :key="element.id"
                  v-if="
                    element.show &&
                    element.id == 'streaming' &&
                    gameday &&
                    gameday.game &&
                    gameday.game.coverage.streaming
                  "
                >
                  <div class="c-coverage__icons--item-icon">
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <a
                    :href="gameday.game.coverage.streaming"
                    v-if="
                      gameday && gameday.game && gameday.game.coverage.streaming
                    "
                    class="c-coverage__primary"
                  >
                    Watch Live
                  </a>
                </div>
                <div
                  class="
                    c-coverage__icons--item
                    flex-basis-100
                    bp-768:flex-basis-50
                    bp-1024:flex-basis-33
                    display-flex
                    flex-direction-column
                    align-items-center
                    justify-content-center
                    text-align-center
                  "
                  :key="element.id"
                  v-if="
                    element.show &&
                    element.id == 'audio' &&
                    gameday &&
                    gameday.game &&
                    gameday.game.coverage.audio
                  "
                >
                  <div class="c-coverage__icons--item-icon">
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <a
                    :href="gameday.game.coverage.audio"
                    v-if="
                      gameday && gameday.game && gameday.game.coverage.audio
                    "
                    class="c-coverage__primary"
                  >
                    Listen Live
                  </a>
                </div>
                <div
                  class="
                    c-coverage__icons--item
                    flex-basis-100
                    bp-768:flex-basis-50
                    bp-1024:flex-basis-33
                    display-flex
                    flex-direction-column
                    align-items-center
                    justify-content-center
                    text-align-center
                  "
                  :key="element.id"
                  v-if="
                    element.show &&
                    element.id == 'stats' &&
                    gameday &&
                    gameday.game &&
                    gameday.game.coverage.stats
                  "
                >
                  <div class="c-coverage__icons--item-icon">
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <a
                    :href="gameday.game.coverage.stats"
                    v-if="
                      gameday && gameday.game && gameday.game.coverage.stats
                    "
                    class="c-coverage__primary"
                  >
                    Follow Live
                  </a>
                </div>
                <div
                  class="
                    c-coverage__icons--item
                    flex-basis-100
                    bp-768:flex-basis-50
                    bp-1024:flex-basis-33
                    display-flex
                    flex-direction-column
                    align-items-center
                    justify-content-center
                    text-align-center
                  "
                  :key="element.id"
                  v-if="
                    element.show &&
                    element.id == 'tickets' &&
                    gameday &&
                    gameday.game &&
                    gameday.game.coverage.tickets
                  "
                >
                  <div class="c-coverage__icons--item-icon custom">
                    <i class="sf-tickets-2"></i>
                  </div>
                  <a
                    :href="gameday.game.coverage.tickets"
                    v-if="
                      gameday && gameday.game && gameday.game.coverage.tickets
                    "
                    class="c-coverage__primary"
                  >
                    Tickets
                  </a>
                </div>
                <div
                  class="
                    c-coverage__icons--item
                    flex-basis-100
                    bp-768:flex-basis-50
                    bp-1024:flex-basis-33
                    display-flex
                    flex-direction-column
                    align-items-center
                    justify-content-center
                    text-align-center
                  "
                  :key="element.id"
                  v-if="
                    element.show &&
                    element.id == 'pregame_story' &&
                    gameday &&
                    gameday.game &&
                    gameday.game.pregame_story &&
                    gameday.game.pregame_story.id
                  "
                >
                  <div class="c-coverage__icons--item-icon">
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <a
                    :href="gameday.game.pregame_story.link"
                    v-if="
                      gameday &&
                      gameday.game &&
                      gameday.game.pregame_story &&
                      gameday.game.pregame_story.id
                    "
                    class="c-coverage__primary"
                  >
                    Story Preview
                  </a>
                </div>
                <div
                  class="
                    c-coverage__icons--item
                    flex-basis-100
                    bp-768:flex-basis-50
                    bp-1024:flex-basis-33
                    display-flex
                    flex-direction-column
                    align-items-center
                    justify-content-center
                    text-align-center
                  "
                  :key="element.id"
                  v-if="
                    element.show &&
                    element.id == 'teamStoreURL' &&
                    section &&
                    section.teamStoreURL
                  "
                >
                  <div class="c-coverage__icons--item-icon">
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <a
                    :href="section.teamStoreURL"
                    v-if="section.teamStoreURL"
                    class="c-coverage__primary"
                  >
                    Shop Online
                  </a>
                </div>
              </template>
              <div
                :key="element.id"
                class="
                  c-coverage__icons--item
                  flex-basis-100
                  bp-768:flex-basis-50
                  bp-1024:flex-basis-33
                  display-flex
                  flex-direction-column
                  align-items-center
                  justify-content-center
                  text-align-center
                "
                v-if="element.is_custom && element.show"
              >
                <div class="c-coverage__icons--item-icon custom">
                  <i
                    v-if="element.is_custom && element.icon"
                    :class="element.icon"
                  ></i>
                </div>
                <div class="c-coverage__primary" v-if="!element.link_url">
                  {{ element.link_text }}
                </div>
                <a
                  class="c-coverage__primary"
                  v-if="element.link_url"
                  :href="element.link_url"
                  >{{ element.link_text }}</a
                >
              </div>
            </template>
          </div>
        </div>
      </div>
      <div
        v-if="template.name === 'coverage-2'"
        class="
          c-coverage--template-2
          row
          width-100
          position-relative
          z-index-3
          padding-x-12
        "
      >
        <div class="c-coverage__icons--list bp-768:padding-x-24">
          <template v-for="element in section.coverageElements">
            <template>
              <template v-if="!element.is_custom">
                <div
                  :key="element.id"
                  class="c-coverage__icons--item display-flex"
                  v-if="element.show && element.id == 'date'"
                >
                  <div
                    class="
                      bp-768:display-none
                      display-flex
                      c-coverage__icons--item-icon
                    "
                  >
                    <feather
                      size="28px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div
                    class="
                      c-coverage__icons--item-icon
                      display-none
                      bp-768:display-block
                    "
                  >
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div class="c-coverage__icons--item-content">
                    <div
                      v-if="gameday && gameday.game && gameday.game.date"
                      class="
                        c-coverage__primary
                        font-size-30
                        bp-768:font-size-48
                      "
                    >
                      {{
                        gameday.game.date
                          ? moment(gameday.game.date).format("MM.DD.YY")
                          : "N/A"
                      }}
                    </div>
                    <div class="c-coverage__secondary">Date</div>
                  </div>
                </div>
                <div
                  :key="element.id"
                  class="c-coverage__icons--item display-flex"
                  v-if="element.show && element.id == 'time'"
                >
                  <div
                    class="
                      bp-768:display-none
                      display-flex
                      c-coverage__icons--item-icon
                    "
                  >
                    <feather
                      size="28px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div
                    class="
                      c-coverage__icons--item-icon
                      display-none
                      bp-768:display-block
                    "
                  >
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div class="c-coverage__icons--item-content">
                    <div
                      v-if="gameday && gameday.game && gameday.game.time"
                      class="
                        c-coverage__primary
                        font-size-30
                        bp-768:font-size-48
                      "
                    >
                      {{ gameday.game.time }}
                    </div>
                    <div class="c-coverage__secondary">Time</div>
                  </div>
                </div>
                <div
                  :key="element.id"
                  class="c-coverage__icons--item display-flex"
                  v-if="element.show && element.id == 'location'"
                >
                  <div
                    class="
                      bp-768:display-none
                      display-flex
                      c-coverage__icons--item-icon
                    "
                  >
                    <feather
                      size="28px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div
                    class="
                      c-coverage__icons--item-icon
                      display-none
                      bp-768:display-block
                    "
                  >
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div class="c-coverage__icons--item-content">
                    <div
                      class="
                        c-coverage__primary
                        font-size-30
                        bp-768:font-size-48
                      "
                    >
                      Location
                    </div>
                    <div
                      v-if="gameday && gameday.game && gameday.game.location"
                      class="c-coverage__secondary"
                    >
                      <div class="margin-b-6">{{ gameday.game.location }}</div>
                      <a
                        v-if="
                          gameday.facility.featured != null &&
                          gameday.facility.featured
                        "
                        :href="'/facilities/?id=' + gameday.facility.id"
                      >
                        {{ gameday.facility.title }}
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  :key="element.id"
                  class="c-coverage__icons--item display-flex"
                  v-if="
                    element.show &&
                    element.id == 'tv' &&
                    gameday &&
                    gameday.game &&
                    gameday.game.coverage.tv
                  "
                >
                  <div
                    class="
                      bp-768:display-none
                      display-flex
                      c-coverage__icons--item-icon
                    "
                  >
                    <feather
                      size="28px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div
                    class="
                      c-coverage__icons--item-icon
                      display-none
                      bp-768:display-block
                    "
                  >
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div class="c-coverage__icons--item-content">
                    <div
                      v-if="gameday && gameday.game && gameday.game.coverage.tv"
                      class="
                        c-coverage__primary
                        font-size-30
                        bp-768:font-size-48
                      "
                      v-html="gameday.game.coverage.tv"
                    ></div>
                  </div>
                </div>
                <div
                  :key="element.id"
                  class="c-coverage__icons--item display-flex"
                  v-if="
                    element.show &&
                    element.id == 'radio' &&
                    gameday &&
                    gameday.game &&
                    gameday.game.coverage.radio
                  "
                >
                  <div
                    class="
                      bp-768:display-none
                      display-flex
                      c-coverage__icons--item-icon
                    "
                  >
                    <feather
                      size="28px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div
                    class="
                      c-coverage__icons--item-icon
                      display-none
                      bp-768:display-block
                    "
                  >
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div class="c-coverage__icons--item-content">
                    <div
                      v-if="
                        gameday && gameday.game && gameday.game.coverage.radio
                      "
                      class="
                        c-coverage__primary
                        font-size-30
                        bp-768:font-size-48
                      "
                      v-html="gameday.game.coverage.radio"
                    ></div>
                  </div>
                </div>
                <div
                  :key="element.id"
                  class="c-coverage__icons--item display-flex"
                  v-if="
                    element.show &&
                    element.id == 'streaming' &&
                    gameday &&
                    gameday.game &&
                    gameday.game.coverage.streaming
                  "
                >
                  <div
                    class="
                      bp-768:display-none
                      display-flex
                      c-coverage__icons--item-icon
                    "
                  >
                    <feather
                      size="28px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div
                    class="
                      c-coverage__icons--item-icon
                      display-none
                      bp-768:display-block
                    "
                  >
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div class="c-coverage__icons--item-content">
                    <a
                      :href="gameday.game.coverage.streaming"
                      v-if="
                        gameday &&
                        gameday.game &&
                        gameday.game.coverage.streaming
                      "
                      class="
                        c-coverage__primary
                        font-size-30
                        bp-768:font-size-48
                      "
                    >
                      Watch Live
                    </a>
                  </div>
                </div>
                <div
                  :key="element.id"
                  class="c-coverage__icons--item display-flex"
                  v-if="
                    element.show &&
                    element.id == 'audio' &&
                    gameday &&
                    gameday.game &&
                    gameday.game.coverage.audio
                  "
                >
                  <div
                    class="
                      bp-768:display-none
                      display-flex
                      c-coverage__icons--item-icon
                    "
                  >
                    <feather
                      size="28px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div
                    class="
                      c-coverage__icons--item-icon
                      display-none
                      bp-768:display-block
                    "
                  >
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div class="c-coverage__icons--item-content">
                    <a
                      :href="gameday.game.coverage.audio"
                      v-if="
                        gameday && gameday.game && gameday.game.coverage.audio
                      "
                      class="
                        c-coverage__primary
                        font-size-30
                        bp-768:font-size-48
                      "
                    >
                      Listen Live
                    </a>
                  </div>
                </div>
                <div
                  :key="element.id"
                  class="c-coverage__icons--item display-flex"
                  v-if="
                    element.show &&
                    element.id == 'stats' &&
                    gameday &&
                    gameday.game &&
                    gameday.game.coverage.stats
                  "
                >
                  <div
                    class="
                      bp-768:display-none
                      display-flex
                      c-coverage__icons--item-icon
                    "
                  >
                    <feather
                      size="28px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div
                    class="
                      c-coverage__icons--item-icon
                      display-none
                      bp-768:display-block
                    "
                  >
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div class="c-coverage__icons--item-content">
                    <a
                      :href="gameday.game.coverage.stats"
                      v-if="
                        gameday && gameday.game && gameday.game.coverage.stats
                      "
                      class="
                        c-coverage__primary
                        font-size-30
                        bp-768:font-size-48
                      "
                    >
                      Follow Live
                    </a>
                  </div>
                </div>
                <div
                  :key="element.id"
                  class="c-coverage__icons--item display-flex"
                  v-if="
                    element.show &&
                    element.id == 'tickets' &&
                    gameday &&
                    gameday.game &&
                    gameday.game.coverage.tickets
                  "
                >
                  <div
                    class="
                      bp-768:display-none
                      display-flex
                      c-coverage__icons--item-icon
                      custom
                    "
                  >
                    <i class="sf-tickets-2"></i>
                  </div>
                  <div
                    class="
                      c-coverage__icons--item-icon
                      custom
                      display-none
                      bp-768:display-block
                    "
                  >
                    <i class="sf-tickets-2"></i>
                  </div>
                  <div class="c-coverage__icons--item-content">
                    <a
                      :href="gameday.game.coverage.tickets"
                      v-if="
                        gameday && gameday.game && gameday.game.coverage.tickets
                      "
                      class="
                        c-coverage__primary
                        font-size-30
                        bp-768:font-size-48
                      "
                    >
                      Tickets
                    </a>
                  </div>
                </div>
                <div
                  :key="element.id"
                  class="c-coverage__icons--item display-flex"
                  v-if="
                    element.show &&
                    element.id == 'pregame_story' &&
                    gameday &&
                    gameday.game &&
                    gameday.game.pregame_story &&
                    gameday.game.pregame_story.id
                  "
                >
                  <div
                    class="
                      bp-768:display-none
                      display-flex
                      c-coverage__icons--item-icon
                    "
                  >
                    <feather
                      size="28px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div
                    class="
                      c-coverage__icons--item-icon
                      display-none
                      bp-768:display-block
                    "
                  >
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div class="c-coverage__icons--item-content">
                    <a
                      :href="gameday.game.pregame_story.link"
                      v-if="
                        gameday &&
                        gameday.game &&
                        gameday.game.pregame_story &&
                        gameday.game.pregame_story.id
                      "
                      class="
                        c-coverage__primary
                        font-size-30
                        bp-768:font-size-48
                      "
                    >
                      Story Preview
                    </a>
                  </div>
                </div>
                <div
                  :key="element.id"
                  class="c-coverage__icons--item display-flex"
                  v-if="
                    element.show &&
                    element.id == 'teamStoreURL' &&
                    section &&
                    section.teamStoreURL
                  "
                >
                  <div
                    class="
                      bp-768:display-none
                      display-flex
                      c-coverage__icons--item-icon
                    "
                  >
                    <feather
                      size="28px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div
                    class="
                      c-coverage__icons--item-icon
                      display-none
                      bp-768:display-block
                    "
                  >
                    <feather
                      size="48px"
                      v-if="
                        !element.is_custom && elementIcon(element.id) != null
                      "
                      :type="elementIcon(element.id)"
                    ></feather>
                  </div>
                  <div class="c-coverage__icons--item-content">
                    <a
                      :href="section.teamStoreURL"
                      v-if="section && section.teamStoreURL"
                      class="
                        c-coverage__primary
                        font-size-30
                        bp-768:font-size-48
                      "
                    >
                      Shop Online
                    </a>
                  </div>
                </div>
              </template>
              <template v-if="element.is_custom">
                <div
                  :key="element.id"
                  class="c-coverage__icons--item display-flex"
                >
                  <div class="c-coverage__icons--item-icon custom">
                    <i
                      v-if="element.is_custom && element.icon"
                      :class="element.icon"
                    ></i>
                  </div>
                  <div class="c-coverage__icons--item-content">
                    <div
                      class="
                        c-coverage__primary
                        font-size-30
                        bp-768:font-size-48
                      "
                      v-if="!element.link_url"
                    >
                      {{ element.link_text }}
                    </div>
                    <a
                      class="
                        c-coverage__primary
                        font-size-30
                        bp-768:font-size-48
                      "
                      v-if="element.link_url"
                      :href="element.link_url"
                      >{{ element.link_text }}</a
                    >
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader.vue";
import SectionBase from "./SectionBase";
export default {
  extends: SectionBase,
  name: "SectionCoverage",
  components: { CommonHeader },
  data: () => ({}),
  methods: {
    elementIcon: function (icon) {
      if (icon === "date") return "calendar";
      else if (icon === "time") return "clock";
      else if (icon === "location") return "map-pin";
      else if (icon === "tv") return "tv";
      else if (icon === "streaming") return "video";
      else if (icon === "radio") return "radio";
      else if (icon === "audio") return "volume-2";
      else if (icon === "stats") return "bar-chart-2";
      else if (icon === "teamStoreURL") return "shopping-cart";
      else if (icon === "tickets") return "shopping-cart";
      else if (icon === "pregame_story") return "file-text";
      else return "link";
    },
  },
  computed: {
    styledBg: function () {
      if (this.section.backgroundImage) {
        return {
          "background-image": "url(" + this.section.backgroundImage + ") ",
        };
      } else if (this.section.useCustomBackgroundColor) {
        return { backgroundColor: this.section.custom_background_color };
      }
      return {};
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.c-coverage {
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: $main-color;
    opacity: 0.7;
    z-index: 2;
  }
}
.c-coverage--template-1 {
  .c-coverage {
    &__primary {
      font-size: 3rem;
      font-weight: 700;
      line-height: 125%;
      color: $main-font-color;
      margin: 12px 0 0 0;
      text-decoration: none;

      a {
        color: $main-font-color;
      }
    }
    &__secondary {
      font-size: 1rem;
      line-height: 125%;
      color: $main-font-color;
      margin: 12px 0 0 0;
      a {
        color: $main-font-color;
      }
    }
    &__icons {
      margin: 24px 0;
      &--list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
        border-top: 1px solid $main-border-color;
        border-left: 1px solid $main-border-color;
      }
      &--item {
        min-height: 354px;
        padding: 24px;
        background: $main-color;
        border-bottom: 1px solid $main-border-color;
        border-right: 1px solid $main-border-color;
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        &-icon {
          color: $color-primary;
          &.custom {
            font-size: 3rem;
          }
        }
      }
    }
  }
}
.c-coverage--template-2 {
  .c-coverage {
    &__primary {
      font-weight: 700;
      line-height: 125%;
      color: $main-font-color;
      text-decoration: none;

      a {
        color: $color-safe-text;
      }
    }
    &__secondary {
      margin: 6px 0 0 0;
      font-size: 1rem;
      line-height: 125%;
      color: $main-font-color;
      a {
        color: $color-safe-text;
      }
    }
    &__icons {
      margin: 90px 0;
      &--list {
        width: 100%;
        margin: 0 auto;
        max-width: 1100px;
        padding: 24px 0;
      }
      &--item {
        min-height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 24px 48px;
        border-bottom: 1px dashed $main-border-color;
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        &-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
        &-icon {
          color: $color-primary;
          margin: 0 48px 0 0;
          &.custom {
            font-size: 3rem;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .c-coverage {
      &__icons--item-icon {
        margin-right: 24px;
        &.custom {
          font-size: 1.75rem;
        }
      }
    }
  }
}
</style>
